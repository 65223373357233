export function run() {
  const container = document.querySelector("[data-controller=TogglePasswordVisibility]");

  if (!container) return;

  const button = container.querySelector("[data-action='toggle-password']");
  const passwordInput = container.querySelector("input[type='password']");

  function hidePassword() {
    passwordInput.type = "password";
    button.classList.remove("password-shown");
    button.classList.add("password-hidden");
    button.setAttribute("aria-pressed", "false");
    button.setAttribute("aria-label", "Show password");
  }

  function showPassword() {
    passwordInput.type = "text";
    button.classList.remove("password-hidden");
    button.classList.add("password-shown");
    button.setAttribute("aria-pressed", "true");
    button.setAttribute("aria-label", "Hide password");
  }

  function togglePassword(event) {
    event.preventDefault();
    if (passwordInput.type === "password") {
      showPassword();
    } else {
      hidePassword();
    }
  }

  button.addEventListener("click", togglePassword);
}
